/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import t from "./Error.js";
import { abortMaybe as r } from "./maybe.js";
import { EsriPromise as s } from "./Promise.js";
import { onAbortOrThrow as l } from "./promiseUtils.js";
import e from "./Warning.js";
import { property as a } from "./accessorSupport/decorators/property.js";
import { subclass as n } from "./accessorSupport/decorators/subclass.js";
const i = "not-loaded",
  d = "loading",
  h = "failed",
  c = "loaded",
  u = s => {
    let u = class extends s {
      constructor(...o) {
        super(...o), this._loadController = null, this.loadError = null, this.loadStatus = "not-loaded", this._set("loadWarnings", []), this.addResolvingPromise(new Promise(o => {
          const r = this.load.bind(this);
          this.load = s => {
            const e = new Promise((o, r) => {
              const e = l(s, r);
              this.destroyed && r(new t("load:instance-destroyed", `Instance of '${this.declaredClass || this.constructor.name}' is already destroyed`, {
                instance: this
              })), this.when(o, r).finally(() => {
                e && e.remove();
              });
            });
            if (this.loadStatus === i) {
              this._set("loadStatus", d);
              const o = this._loadController = new AbortController();
              r({
                signal: o.signal
              });
            }
            return o(), e;
          };
        })), this.when(() => {
          this._set("loadStatus", c), this._loadController = null;
        }, o => {
          this._set("loadStatus", h), this._set("loadError", o), this._loadController = null;
        });
      }
      destroy() {
        this._loadController && (this._loadController = r(this._loadController), this._promiseProps.abort()), this._set("loadError", null), this._set("loadWarnings", []);
      }
      get loaded() {
        return this.loadStatus === c;
      }
      get loadWarnings() {
        return this._get("loadWarnings");
      }
      load() {
        return null;
      }
      cancelLoad() {
        return this.isFulfilled() || (this._set("loadError", new t("load:cancelled", "Cancelled")), this._loadController?.abort(), this._promiseProps.abort()), this;
      }
    };
    return o([a({
      readOnly: !0
    })], u.prototype, "loaded", null), o([a({
      readOnly: !0
    })], u.prototype, "loadError", void 0), o([a({
      clonable: !1
    })], u.prototype, "loadStatus", void 0), o([a({
      type: [e],
      readOnly: !0
    })], u.prototype, "loadWarnings", null), u = o([n("esri.core.Loadable")], u), u;
  };
let p = class extends u(s) {};
p = o([n("esri.core.Loadable")], p), function (o) {
  function t(o) {
    return !(!o || !o.load);
  }
  o.LoadableMixin = u, o.isLoadable = t;
}(p || (p = {}));
const m = p;
export { m as default };